//通用的头组件
<template>
  <div class="common_head">
    <!--  导航条部分 -->
    <div class="content">
      <!-- logo部分 -->
      <div class="logo">
        <img height="58px" src="../assets/img/logo.png" />
      </div>

      <!-- 登录后需要显示的部分 -->
      <div class="right_action" v-if="this.$store.state.user.loginState">
        <!-- 赛事中心 -->
        <div class="competition_title" @click.stop="goCompetitionCenter">赛事中心</div>

        <!-- 导航条部分 -->
        <TopNav></TopNav>

        <!-- 消息模块 -->
        <div class="message" @click.stop="goSysMessage">
          <i class="iconfont cloud-xiaoxizhongxin1"></i>
          <div class="tip">
            <span class="num_tip">提醒</span>
            <span class="num_txt" v-if="unReadMsgNum != 0">{{ unReadMsgNum }}</span>
          </div>
        </div>

        <!-- 登录和登录后显示的头像以及角色类型部分 -->
        <div class="user_message">
          <!-- <div style="flex: 1"></div> -->
          <div class="login_info" @mousemove="isShowPop = true" @mouseleave="isShowPop = false">
            <img class="user_img" :src="user_img" width="48px" />
            <div class="user_type">{{ userName }}</div>

            <!-- 用户资料,我的班级,退出登陆的Pop  -->
            <div class="person_msg animated fadeIn" v-show="isShowPop">
              <div class="item_pop" v-for="(item, index) in this.$store.state.user.navArr" :key="index" @click.stop="goItemPage(index)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//引入顶部导航栏
import TopNav from "@/components/TopNav.vue";
import TopNavUnLogin from "@/components/TopNavUnLogin.vue";
export default {
  data() {
    return {
      userName: "",
      user_img: "",
      //是否显示Pop标识
      isShowPop: false,
      //下拉框数据
      navArr: [],
      //是否是登录状态
      //未读消息
      unReadMsgNum: 0,
      //获取未读消息的定时器
      timer: null
    };
  },

  created() {
    //同步登录状态
    // this.initLogin();
  },

  mounted() {
    this.initPopData();
    this.setUserInfo();
  },

  methods: {
    //根据用户角色来配置弹出框的数据
    initPopData() {
      this.navArr.splice(0, this.navArr.length);
      this.navArr.push({ name: "用户资料" });
      //0学生2老师-1游客 3校长 4管理员
      let roleType = this.getUserRole();
      // console.log("test", "当前登录角色是:" + roleType);
      if (roleType == 0 || roleType == -1) {
        //学生或者游客都显示我的班级
        this.navArr.push({ name: "我的班级" });
      } else if (roleType == 2) {
        //老师
        this.navArr.push({ name: "班级管理" });
      }
      this.navArr.push({ name: "退出登录" });
    },

    //获取用户信息
    setUserInfo() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!this.isEmpty(userInfo)) {
        this.user_img = userInfo.user_img;
        this.userName = userInfo.name;

        //请求一次获取未读消息
        this.httpUnReadMsg();

        //注册VueBus的监听
        this.$bus.on("updateUserInfo", () => {
          //重新设置用户头像和姓名
          this.setUserInfo();
        });

        //刷新用户消息
        this.$bus.on("refrshUnReadMsg", () => {
          this.unReadMsgNum += 1;
        });

        //开启定时器获取未读消息
        this.timer = setInterval(() => {
          this.httpUnReadMsg();
        }, 60000);
      }
    },

    //显示Pop弹框的方法
    showPop() {
      this.$emit("showPop");
    },

    //关闭Pop弹框的方法
    closePop() {
      this.$emit("closePop");
    },

    //跳转不同页面
    goItemPage(index) {
      //获取当前的导航条的名称
      let navArrName = this.navArr[index].name;
      if (navArrName == "用户资料") {
        //用户资料
        this.$router.push({ path: "/LoginContain/stuCenter" });
      } else if (navArrName == "我的班级") {
        //学生角色
        if (this.$route.path != "/LoginContain/StudentMyClass") {
          this.$router.push({ path: "/LoginContain/StudentMyClass" });
        }
      } else if (navArrName == "班级管理") {
        //老师角色
        if (this.$route.path != "/LoginContain/TeacherManagerClass") {
          this.$router.push({
            path: "/LoginContain/TeacherManagerClass"
          });
        }
      } else if (navArrName == "退出登录") {
        //退出登录
        this.$emit("showLogout");
      }
      this.isShowPop = false;
    },

    //跳转到系统消息界面
    goSysMessage() {
      if (this.$route.path != "/LoginContain/SysMessage") {
        //将未读消息数置为''
        this.unReadMsgNum = 0;
        this.$router.push({ path: "/LoginContain/SysMessage" });
      }
    },

    //获取系统未读消息
    async httpUnReadMsg() {
      //请求服务器做删除的操作
      //获取页面消息
      let param = this.getHttpParams();
      param.type = 13;
      let res = await this.$http.fetchPost(this.$api.UN_READN_NUM, param);
      if (res.data.state == 200) {
        //获取未读消息
        this.unReadMsgNum = res.data.num;
        // console.log("返回的数据是:", res.data.num);
        // this.unReadMsgNum = 15;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //返回
    goBack() {
      this.$router.replace("/");
    },

    //去往赛事中心
    goCompetitionCenter() {
      //跳转到赛事中心页面
      this.$router.push("/competitonCenter/LessonCenter");
    }
  },
  components: {
    TopNav,
    TopNavUnLogin
  },
  beforeDestroy() {
    //销毁时关闭事件
    this.$bus.off("updateUserInfo");

    this.$bus.off("refrshUnReadMsg");
    //清除定时器
    clearInterval(this.timer);
  }
};
</script>
<style lang="scss" scoped>
.common_head {
  height: 87px;
  width: 100%;
  background: $common_bg;
  .content {
    height: 100%;
    width: $common_width;
    position: relative;
    margin: 0 auto;
    display: flex;
    .logo {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .right_action {
      display: flex;
      align-items: center;
      .competition_title {
        color: white;
        cursor: pointer;
        margin-right: 45px;
        font-weight: bold;
      }
    }

    //登录后信息
    .login_info {
      display: flex;
      align-items: center;
      height: 100%;
      color: white;
      font-size: 14px;
      .user_type {
        margin-left: 12px;
      }
      .user_img {
        width: 50px;
        height: 50px;
        border-radius: 40px;
        object-fit: cover;
        border: 1px solid white;
      }
      .person_msg {
        width: 110px;
        position: absolute;
        top: 87px;
        left: 0px;
        background: white;
        z-index: 999;
        display: flex;
        flex-direction: column;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        box-shadow: 0px 0px 0px 0px #d4d6d7, 0px 0px 0px 0px #d4d6d7, 2px 0px 5px 1px #d4d6d7, 0px 2px 5px 1px #d4d6d7;

        .item_pop {
          cursor: pointer;
          height: 33px;
          align-items: center;
          justify-content: center;
          display: flex;
          color: black;
          font-size: 12px;
        }

        .item_pop:hover {
          background: rgba($color: #000000, $alpha: 0.1);
          color: $common_bg;
        }
      }
    }

    //消息
    .message {
      cursor: pointer;
      margin-left: 12px;
      margin-right: 50px;
      display: flex;
      align-items: center;
      .cloud-xiaoxizhongxin1 {
        color: white;
        font-size: 28px;
      }

      .tip {
        margin-left: 2px;
        //提醒文字
        position: relative;
        .num_tip {
          color: white;
        }

        //未读消息数量
        .num_txt {
          position: absolute;
          top: -16px;
          background: #ff6a6a;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          right: -10px;
          min-width: 20px;
          min-height: 20px;
          // width: 20px;
          // height: 20px;
          color: white;
          font-size: 10px;
        }
      }
    }

    // 用户登录信息以及登录后的头像信息
    .user_message {
      // background: blue;
      position: relative;
      cursor: pointer;
      display: flex;
      height: 100%;
      align-items: center;
      min-width: 160px;
      color: white;
      font-size: 14px;
    }

    .course_style {
      cursor: pointer;
    }
  }
}
</style>
