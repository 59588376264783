//登录后的容器
<template>
  <div class="login_contain">
    <!-- 头部 -->
    <common-head @showLogout="showLogout"></common-head>

    <!-- 用于切换课程中心,创作中心,教研中心以及子页面的roter-view 'CourseCenter', :include="['ChooseChapter', 'ChooseLesson']" -->
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->

    <!-- 退出登录的弹框 -->
    <exit-system :showDialog="isShowLoginOut" :title="title" @doLoginOut="doLoginOut" @close="closeLoginOutDialog"></exit-system>
  </div>
</template>
<script>
//引入头部组件
import CommonHead from "@/components/CommonHead";

//退出登录的弹框
import exitSystem from "@/views/dialog/ExitSystem";

export default {
  name: "LoginContain",
  data() {
    return {
      //是否显示退出登录的弹框控制值
      isShowLoginOut: false,

      //退出登录的弹框标题
      title: "退出登录"
    };
  },
  created() {},
  methods: {
    //显示退出登录的弹框
    showLogout() {
      this.isShowLoginOut = true;
    },

    //关闭退出登录的弹框
    closeLoginOutDialog() {
      this.isShowLoginOut = false;
    },

    //退出登录的操作
    doLoginOut() {
      //退出登录的操作
      localStorage.removeItem("userInfo");
      localStorage.removeItem("autoLogin");
      //还原vueX的数据状态
      this.$store.commit("user/setLoginState", false);
      this.$store.commit("user/setUserInfo", {});
      this.$store.commit("user/setNavArr", []);
      this.$router.replace("/");
    }
  },
  computed: {},
  components: {
    CommonHead,
    exitSystem
  }
};
</script>
<style lang="scss" scoped>
//Pop相关
.login_contain {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .pop_content {
    position: relative;
    height: 100%;
    width: $common_width;
    position: relative;
    margin: 0 auto;
    .person_msg {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0px;
      right: 0;
      background: white;
      z-index: 999;
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      box-shadow: 0px 0px 0px 0px #d4d6d7, 0px 0px 0px 0px #d4d6d7, 2px 0px 5px 1px #d4d6d7, 0px 2px 5px 1px #d4d6d7;

      .item_pop {
        cursor: pointer;
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        color: black;
        font-size: 12px;
      }

      .item_pop:hover {
        background: rgba($color: #000000, $alpha: 0.1);
        color: $common_bg;
      }
    }
  }
}
</style>
